import axios from 'axios'

const request = axios.create({
  baseURL: 'http://129.211.173.188:8000/',
})

// 更新用户配置
export function updateUserApi(id, data) {
  return request({
    method: 'PATCH',
    url: `/users/${id}/`,
    data
  })
}

// 获取doctor列表
export function getDoctorsApi(params) {
  return request({
    method: 'GET',
    url: '/doctors/',
    params
  })
}

// 获取doctor
export function getDoctorApi(id) {
  return request({
    method: 'GET',
    url: `/doctors/${id}`,
  })
}

// 创建doctor
export function createDoctorApi(data) {
  return request({
    method: 'POST',
    url: '/doctors/',
    data
  })
}

// 更新doctor
export function updateDoctorApi(id, data) {
  return request({
    method: 'PATCH',
    url: `/doctors/${id}/`,
    data
  })
}

export function deleteDoctorApi(id) {
  return request({
    method: 'DELETE',
    url: `/doctors/${id}/`,
  })
}
