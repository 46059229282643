<template>
  <div class="DoctorList">

    <van-nav-bar
      @click-left="onClickLeft"
      left-text="返回"
      left-arrow
    />


    <van-cell @click=selectDoctor(doctor.id) v-for="doctor in doctors" :key="doctor.id" size="large" :title=doctor.name
              :icon="doctor.id === doctorId ? 'checked' : 'circle'"
              :label="doctor.hospital + ' ' + doctor.department + ' ' + doctor.mobile">
      <!-- 使用 right-icon 插槽来自定义右侧图标 -->
      <template #right-icon>
        <van-icon @click="toDoctorModify(doctor.id)" name="edit" class="edit-icon"/>
      </template>
    </van-cell>

    <!--    <van-cell size="large" title="张医生" icon="checked" label="南京市第一医院 感染科">-->
    <!--      &lt;!&ndash; 使用 right-icon 插槽来自定义右侧图标 &ndash;&gt;-->
    <!--      <template #right-icon>-->
    <!--        <van-icon name="edit" class="edit-icon"/>-->
    <!--      </template>-->
    <!--    </van-cell>-->


    <van-button :to="{name:'DoctorAdd'}" plain block icon="plus" type="primary">添加医生</van-button>


    <div style="margin: 16px 16px 8px;">
      <van-button :disabled="doctorId === ''" @click="delDoctor" plain round block type="danger">删除</van-button>

    </div>
    <div style="margin: 0 16px 16px;">
      <van-button @click="selected" :disabled="doctorId === ''" round block type="primary" native-type="submit">确定</van-button>
    </div>


  </div>

</template>

<script>
import { getDoctorsApi, deleteDoctorApi } from '@/api/user'
import { Dialog, Toast } from 'vant'

export default {
  name: 'DoctorList',
  metaInfo: {
    title: '医生列表',
  },
  created() {
    this.initUser()
    this.getDoctors({ user_id: this.userInfo.id })
    // this.getDoctors({ user_id: 7 })
  },
  data() {
    return {
      userInfo: {
        city: '',
        headimgurl: '',
        id: '',
        mobile: '',
        name: '',
        nickname: '',
        openid: '',
        role: ''
      },
      doctorId: '',
      doctors: []
    }
  },
  methods: {
    selected() {
      const doctorInfo = this.doctors.filter((item)=>{return item.id === this.doctorId})[0]
      localStorage.setItem('doctorInfo', JSON.stringify(doctorInfo))
      // if (this.$route.params.from === 'edit') {
      //   this.$router.go(-1)
      // }else {
      this.$router.go(-1)
      // }

    // :to="{name: 'SampleAdd', params: {doctor: }}"
    },
    delDoctor() {
      Dialog.alert({
        message: '确定删除吗',
      }).then(() => {
        deleteDoctorApi(this.doctorId).then(()=>{
          Toast.success('删除成功')
          this.getDoctors({ user_id: this.userInfo.id })
        })
      })
    },
    toDoctorModify(doctorId) {
      this.$router.push({
        name: 'DoctorModify',
        params: { doctor_id: doctorId }
      })
    },
    getDoctors(params) {
      getDoctorsApi(params).then(res => {
        this.doctors = res.data.results
      })
    },
    selectDoctor(doctorId) {
      this.doctorId = doctorId
    }

  }
}
</script>

<style scoped>
.van-icon {
  width: 1.25em;
  font-size: 20px;
}

.van-icon-circle {
  color: #fff;
}

.van-icon-checked {
  color: #00a29a;
}

.edit-icon {
  font-size: 25px;
  margin: auto;
  /*line-height: inherit;*/
}

.van-button--primary {
  border: 1px #00a29a;
}
</style>
